<template>
    <nav class="navigation-bar">
        <ul class="navigation-bar__list">
            <li 
                v-for="(entry, key) in entries"
                :key="key"
                class="navigation-bar__list-item"
                :class="{ 'navigation-bar__list-item--active' : isCurrentPath(entry.path)}"
            >
                <router-link :to="entry.path">
                    <icon :icon="entry.icon" class="navigation-bar__icon"/> {{key}}
                </router-link>
            </li>
        </ul>
    </nav>
</template>

<script>
    export default {
        name: 'navigationBar',
        props: {
            entries: {
                type: Object,
                required: true,
            },
        },
        computed: {
            currentPath() {
                return this.$route.path || '';
            }
        },
        methods: {
            isCurrentPath(path) {
                return this.currentPath === path;
            }
        }
    }
</script>

<style>
    .navigation-bar {
        position: absolute;
        top: 0;
        width: 100%;
        font-family: 'Orbitron', Helvetica, Arial, sans-serif;
        letter-spacing: .25rem;
        padding: 1rem;
        background-color: rgba(0, 0, 0, 0.3);
        height: var(--grid-step-height);
    }

    .navigation-bar__list {
        display: flex;
        align-items: stretch;
        list-style-type: none;
        margin: 0;
        padding: 0;
        user-select: none;
    }

    .navigation-bar__list-item {
        flex: 1;
        display: inline;
        line-height: 4rem;
    }

    .navigation-bar__list-item--active {
        color: var(--edev-color-orange);
    }
</style>
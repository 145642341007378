<template>
  <div class="motor" :class="{ 'motor--brushless': brushless, 'motor--large': large }">
    <img class="motor__poles" alt="motor loader poles" :src="require(`@/assets/motor/electric motor poles.svg`)" />
    <img class="motor__armature" alt="motor loader armature" :src="require(`@/assets/motor/electric motor coils.svg`)" />
  </div>
</template>

<script>
export default {
  name: 'motor',
  props: {
    brushless: {
      type: Boolean,
      default: false
    },
    large: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style>
.motor {
  width: var(--grid-step-height);
  height: var(--grid-step-height);
}

.motor__poles {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.motor__armature {
  position: absolute;
  left: 0;
  top: 0;
  animation: motor-spin 2s linear infinite;
  width: 100%;
  height: 100%;
}

.motor--large {
  width: calc(var(--grid-step-height) * 3);
  height: calc(var(--grid-step-height) * 3);
}

.motor--brushless .motor__armature {
  animation: none;
}

.motor--brushless .motor__poles {
  animation: motor-spin 2s linear infinite;
}

@keyframes motor-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}
</style>
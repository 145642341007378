<template>
  <div id="app">
    <div class="app__navigation">
      <navigation-bar :entries="menuOptions"/>
    </div>
    <div 
      class="app__page"
      :class="{ 'app__page--visible': pageVisible }">
      <router-view 
        class="page"
        :page-started="pageStarted"
        ></router-view>
    </div>
    <div class="app__hero">
      <hero 
        :fullscreen="true"
        :source="currentHero"
        @animationFinished="showPage"/>
    </div>
  </div>
</template>

<script>
import navigationBar from '@/components/App/navigationBar';
import hero from '@/components/App/hero';

export default {
  name: 'App',
  data() {
    return {
      menuOptions: {},
    }
  },
  components: {
    navigationBar,
    hero
  },
  computed: {
    currentHero() {
      return this.$store.state.currentHero;
    },
    pageVisible() {
      return this.$store.state.pageVisible;
    },
    pageStarted() {
      return this.$store.state.pageStarted;
    }
  },
  async mounted() {
    this.menuOptions = await this.$store.dispatch('getPageInfo');
  },
  methods: {
    showPage() {
      this.$store.commit('setPageVisible', true);

      setTimeout(() => {
        this.$store.commit('setPageStarted', true);
      }, 250)
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Orbitron|Ubuntu&display=swap');
@import url('./styles/general.css');
@import url('./styles/headers.css');
@import url('./styles/pages.css');
@import url('./styles/variables.css');

#app {
  font-family: 'Ubuntu', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--edev-color-white);
  font-size: 2rem;
  letter-spacing: 0.5px;

  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
}

.app__hero {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  z-index: 1;
}

.app__page {
  position: inherit;
  width: 100%;
  height: auto;
  z-index: 2;
  opacity: 0;
  transition: opacity 0.25s linear;
}

.app__page--visible {
  opacity: 1;
}

.app__navigation {
  position: inherit;
  width: 100%;
  height: auto;
  z-index: 4;
}

.app__footer {
  position: absolute;
  bottom: 0;
  z-index: 3;
}
</style>

import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/stores/store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { faHome, faBookOpen } from '@fortawesome/free-solid-svg-icons';
import { faAws, faVuejs, faGitlab } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

library.add(faHome);
library.add(faBookOpen);
library.add(faAws);
library.add(faVuejs);
library.add(faGitlab);

createApp(App)
  .use(router)
  .use(store)
  .component('icon', FontAwesomeIcon)
  .mount('#app');

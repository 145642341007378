import { createStore } from "vuex";
import axios from 'axios';

export default createStore({
    state: {
        currentHero: '',
        pageVisible: false,
        pageStarted: false,
        pageInfo: {},
    },
    mutations: {
        setHero(state, hero) {
            state.currentHero = hero;
        },
        setPageVisible(state, visible) {
            state.pageVisible = visible;
        },
        setPageStarted(state, started) {
            state.pageStarted = started;
        }
    },
    actions: {
        async getPageInfo() {
            if (Object.keys(this.state.pageInfo).length === 0 && this.state.pageInfo.constructor === Object) {
                const { data } = await axios.get('/static/data/pageInfo.json');

                this.state.pageInfo = data.data;
            }

            return this.state.pageInfo;
        }
    }
})
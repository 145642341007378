import { createRouter, createWebHistory } from 'vue-router';
import homePage from '@/pages/homePage'
import store from '@/stores/store'

const DEFAULT_TITLE = 'Erikdevelop.com';

const beforeEnter = (to, from, next) => {
  store.commit('setPageVisible', false);
  setTimeout(() => {
    document.title = to.meta.title || DEFAULT_TITLE;
    store.commit('setPageStarted', false);
    next();
  }, 250);
}

const router = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: "Home",
      component: homePage,
      meta: {
        title: 'Home - Erikdevelop.com'
      },
      beforeEnter: beforeEnter
    }
  ]
});

export default router
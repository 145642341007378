<template>
    <div class="hero" :class="{'hero--fullscreen': fullscreen, 'hero--animating': animating}">
        <div class="hero__grid"></div>
        <div class="hero__image hero__image--new" 
            :style="currentImage"></div>
        <div class="hero__image hero__image--old" 
            :style="previousImage"></div>
        <svg class="hero__animation hero__animation--lines" width="100%" height="100%"></svg>
        <svg class="hero__animation hero__animation--mask" width="100%" height="100%">
            <defs>
                <clipPath id="hero-animation-clip-path">
                </clipPath>
            </defs>
        </svg>
        <motor 
            class="hero__motor"
            :class="{'hero__motor--visible': motorVisible}"
            :brushless="true"
            :large="true"/>
    </div>
</template>

<script>
    import motor from '@/components/Common/motor';
    
    export default {
        name: 'hero',
        components: {
            motor,
        },
        props: {
            fullscreen: {
                type: Boolean,
                default: false,
            },
            source: {
                type: String,
                required: true,
            }
        },
        data() {
            return {
                newSource: '',
                oldSource: '',
                fps: 18,
                animating: false,
                motorVisible: false,
            }
        },
        computed: {
            currentImage() {
                return `background-image: url('/static/images/${ this.newSource }');`;
            },
            previousImage() {
                return `background-image: url('/static/images/${ this.oldSource }');`;
            }
        },
        watch: {
            source: function(newSource, oldSource) {
                this.motorVisible = true;
                let newImage = new Image();

                newImage.addEventListener('load', () => {
                    this.motorVisible = false;
                    this.runTransitionAnimation(newSource, oldSource);
                });

                newImage.src = `/static/images/${newSource}`;
            }
        },
        methods: {
            runTransitionAnimation(newSource, oldSource) {
                this.oldSource = oldSource;
                this.newSource = newSource;
                this.animating = true;
                this.animateTransitionLines();
                this.animateTransitionMask();
            },
            animateTransitionLines() {
                const transitionCanvas = this.getAnimationCanvas('.hero__animation--lines');

                let currentColumn = 0;
                let currentRow = 0;

                transitionCanvas.element.innerHTML = '';

                let linesAnimationLoop = setInterval(() => {
                    transitionCanvas.element.innerHTML = '';

                    
                    this.drawTopPolyLine(transitionCanvas.element, currentColumn, currentRow);
                    this.drawBottomPolyLine(transitionCanvas.element, currentColumn, currentRow, 0.5);
                    this.drawTopPolyLine(transitionCanvas.element, currentColumn + 1, currentRow, 1);
                    this.drawBottomPolyLine(transitionCanvas.element, currentColumn + 1, currentRow);

                    currentColumn >= transitionCanvas.horizontalSquares ? currentRow++ : currentColumn++;

                    if (currentColumn >= transitionCanvas.horizontalSquares && currentRow >= transitionCanvas.verticalSquares) {
                        clearInterval(linesAnimationLoop);
                        this.animating = false;
                        this.$emit('animation-finished');
                    }
                }, 1000 / this.fps);
            },
            animateTransitionMask() {
                const transitionCanvas = this.getAnimationCanvas('.hero__animation--mask', '#hero-animation-clip-path');
                let currentColumn = 0;
                let currentRow = 0;

                transitionCanvas.element.innerHTML = '';

                let maskAnimationLoop = setInterval(() => {
                    this.drawTopPolyLine(transitionCanvas.element, currentColumn, currentRow, true);
                    this.drawBottomPolyLine(transitionCanvas.element, currentColumn, currentRow, true);

                    currentColumn >= transitionCanvas.horizontalSquares ? currentRow++ : currentColumn++;

                    if (currentColumn >= transitionCanvas.horizontalSquares && currentRow >= transitionCanvas.verticalSquares) {
                        clearInterval(maskAnimationLoop);
                    }
                }, 1000 / this.fps);
            },
            getAnimationCanvas(selector, clipPath = null) {
                const transitionCanvas = this.$el.querySelector(selector);
                const canvasBounds = transitionCanvas.getBoundingClientRect();
                const horizontalSquares = Math.ceil(canvasBounds.width / 49);
                const verticalSquares = Math.ceil(canvasBounds.height / 49);

                if (clipPath) {
                    const clipPathElement = this.$el.querySelector(clipPath);

                    return {
                        element: clipPathElement,
                        horizontalSquares: horizontalSquares,
                        verticalSquares: verticalSquares
                    };
                }

                return {
                    element: transitionCanvas,
                    horizontalSquares: horizontalSquares,
                    verticalSquares: verticalSquares
                };
            },
            drawTopPolyLine(clipPath, rowX, rowY, opacity = 0) {
                for (let index = 0; index < rowX + 1; index++) {
                    clipPath.append(this.drawTopPolygon(rowX - index, rowY + index, opacity));
                }

            },
            drawBottomPolyLine(clipPath, rowX, rowY, opacity = 0) {
                for (let index = 0; index <= rowX; index++) {
                    clipPath.append(this.drawBottomPolygon(rowX - index, rowY + index, opacity));
                }
            },
            drawTopPolygon(rowX, rowY, opacity = 0) {
                let newPoly = document.createElementNS("http://www.w3.org/2000/svg", 'polygon');
                let points = `${rowX * 49},${rowY * 49} ${(rowX + 1) * 49},${rowY * 49} ${rowX * 49},${(rowY + 1) * 49}`;
                newPoly.setAttribute("points", points);
                newPoly.setAttribute("style", `stroke:orange; fill:#121212; fill-opacity:${opacity}`);

                return newPoly;
            },
            drawBottomPolygon(rowX, rowY, opacity = 0) {
                let newPoly = document.createElementNS("http://www.w3.org/2000/svg", 'polygon');
                let points = `${rowX * 49},${(rowY + 1) * 49} ${(rowX + 1) * 49},${(rowY + 1) * 49} ${(rowX + 1) * 49},${rowY * 49}`;
                newPoly.setAttribute("points", points);
                newPoly.setAttribute("style", `stroke:orange; fill:#121212; fill-opacity:${opacity}`);

                return newPoly;
            },
        },
    }
</script>

<style>
    .hero {
        position: relative;
        width: 100%;
        height: calc(15 * var(--grid-step-height) + 1px);
        transition: height 1s fade-in-out;
        overflow: hidden;
    }

    .hero__grid {
        position: inherit;
        width: 100%;
        height: 100%;
        background-image: url('~@/assets/grid-images/polyright.png');
        opacity: .15;
        z-index: 5;
    }

    .hero__image {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        transition: height 1s ease-in-out;
    }

    .hero__image--old {
        z-index: 1;
    }

    .hero__animation {
        position: absolute;
        top: 0;
        left: 0;
    }

    .hero__animation--mask {
        z-index: 4;
    }

    .hero__animation--lines {
        z-index: 6;
    }

    .hero__motor {
        position: absolute;
        top: 50%;
        left: 50%;
        z-index: 7;
        margin-left: -75px;
        margin-top: -75px;
        opacity: 0;
        transition: opacity 0.5s linear;
    }

    .hero__motor--visible {
        opacity: 1;
    }

    .hero--fullscreen {
        height: 100vh;
    }

    .hero--animating .hero__image--new {
        clip-path: url(#hero-animation-clip-path);
    }
</style>
<template>
    <main 
      class="home-page"
      :class="{'home-page--started': pageStarted}">
      <h1 class="home-page__header header header--main">{{ pageProperties.title }}</h1>
      <div class="home-page__content">
        <p>
          Welcome to Erikdevelop, the place where Erik shows off his projects and screams his biased, half-educated opinions into the void.
        </p>
        <p>
          As the most degenerate example of crossbreeding, Erik is a webdeveloper that specialises in graphical programming, animations, and 3D visualisation.
        </p>
      </div>
    </main>
</template>

<script>
export default {
  name: 'HomePage',
  props: {
    pageStarted: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      pageProperties: {}
    }
  },
  async mounted() {
    const pageInfo = await this.$store.dispatch('getPageInfo');
    this.pageProperties = pageInfo.Home.properties;

    this.$store.commit('setHero', this.pageProperties.hero);
  }
}
</script>

<style>
  .home-page__content {
    background-color: rgba(0, 0, 0, 0.75);
    padding: 0.25rem;
    margin-top: 1rem;
    max-width: 128rem;
    margin-left: auto;
    margin-right: auto;
    opacity: 0;
    transition: opacity 0.5s linear 0.25s;
  }

  .home-page--started .home-page__content {
    opacity: 1;
  }
</style>